<template>
    <div>
        <div class="container py-5">
            <div class="text-center text-muted">
                <div class="d-flex align-items-center justify-content-center text-darkblue mb-5">
                    <i class="fas fa-low-vision fa-7x"></i>
                    <div class="display-1 fw-bold mb-0 ms-4">404</div>
                </div>
                <div class="h5 fw-light">{{$t('notFound')}}</div>
                <div class="small">
                    <router-link :to="{name: 'Homepage'}">
                        <span>{{$t('goHome')}}</span>
                    </router-link>
                    <span>{{$t('or')}}</span>
                    <span>{{$t('contact')}}</span>
                    <span>{{$t('withAdmin')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "notFound": "Page not found",
        "goHome": "Go back",
        "or": " to homepage or making ",
        "contact": "contact",
        "withAdmin": " with administrator."
    },
    "th": {
        "notFound": "ไม่พบหน้าเว็บดังกล่าว",
        "goHome": "ย้อนกลับ",
        "or": "ไปยังหน้าแรก หรือ",
        "contact": "ติดต่อสอบถาม",
        "withAdmin": "กับผู้ดูแลระบบ"
    }
}
</i18n>